import React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Collection from "../components/collection/collection"

const allCollectionsPage = ({ pageContext }) => {
  let { collections, isShop } = pageContext

  let collectionsComponents = collections.map(collection => {
    return (
      <Collection
        key={collection.uid}
        collection={collection}
        isShop={isShop}
      />
    )
  })

  return (
    <Layout
      isShopRoute={isShop}
      isCollectionsRoute={!isShop}
      pageTitle={"All Collections"}
    >
      <Seo
        title="Collections"
        keywords={[`Collections`, `Design`, `Accessories`, `Fashion`]}
        pathname={`/${isShop ? `shop` : `collection`}/all`}
      />

      {collectionsComponents}
    </Layout>
  )
}

export default allCollectionsPage
